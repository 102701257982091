.form__header {
    margin: 40px auto 20px auto;
    text-align: center;
    font-size: 20px;
}

.form_container {
    border: 7px solid #FDB927;
    width: 50%;
    margin: 50px auto 0 auto;
    padding: 10px;
    border-radius: 5px;
}

.form_container>h2 {
    font-size: 40px;
    font-weight: bold;
    color: #552583;
}

.form__footer {
    text-align: center;
    margin-top: 30px;
}