.TicketsPage {
    margin: 0;
    background: #FDB927;
    padding: 9px;
}

.TicketsPage.search_functions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}