.MainHeader {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
}

.MainHeader>a {
    margin: auto 0;
}

.site_identity{
    margin: auto 20px auto 13%;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.site_identity>h1 {
    margin: auto;
    font-size: 20px;
}

.lakeshow_logo {
    background: white;
    width: 50px;
    height: 50px;
}

.nav_options {
    list-style-type: none;
    margin: auto 0;
    padding: 0;
    overflow: hidden;
    background-color: #FDB927;
    height: 50px;
}

li {
    float: left;
}

li a {
    display: block;
    color: #552583;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

li a:hover {
    background-color: white;
}



