section.WhoWeAre{
    height: 600px;
    background-color: #FDB927;
    margin-top: -22px;
}

.WhoWeAre.image__container {
    position: absolute;
    width: 200px;
    height: 200px;
}

.left__container {
    width: 50%;
    text-align: center;
}

.right__container {
    width: 50%;
    text-align: center;
}

#who_we_are_image_top {
    margin: 40px auto 10px 0px;
    position: absolute;
    left: 13%;
    height: 400px;
    width: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/LakeshowTickets/IMG_20170719_164743.jpg')
}

#who_we_are_image_bottom {
    margin: 0px auto 10px 0;
    position: absolute;
    top: 1034px;
    left: 13%;
    height: 123.4px;
    width: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/LakeshowTickets/Staples-Center-exterior-view.jpg')
}

.WhoWeAre.title__bg {
    position: absolute;
    margin: 0 auto 0 auto;
    text-align: left;
    width: 42%;
    background: #552583;
    right: 0;
    top: 712px;
}

.WhoWeAre>h1 {
    color: #FDB927;
    padding-left: 20px;
    font-weight: bold;
}

#who_we_are_p {
    position: absolute;
    padding: 20px;
}


